import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Container } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './styles/Portfolios.css';
import image1 from '../assets/portfolio-kwangsiew.jpg'
import image2 from '../assets/portfolio-basian.jpg'
import image3 from '../assets/portfolio-fuzhou.jpg'
import image4 from '../assets/portfolio-nus-msl.jpg'

const Portfolios = () => {
  const portfolioItems = [
    {
      imgSrc: image1,
      link: 'https://fb.watch/qCHmhBquwt/',
      title: 'Project Kwang Siew',
      subtitle: 'Plot Listing'
    },
    {
      imgSrc: image2,
      link: 'https://www.facebook.com/PAKSIANH/posts/pfbid0FtBmwcXJRCb7yHpE437o7PugAw4q2dgxzMCsvvki3mKJ5pwCsymrBvoJEuLNDUFKl',
      title: 'Project Pak Sian',
      subtitle: 'Plot Listing'
    },
    {
      imgSrc: image3,
      link: 'https://www.facebook.com/reel/800885085193938',
      title: 'Project Fu Zhou',
      subtitle: 'Plot Listing'
    },
    {
      imgSrc: image4,
      link: 'https://www.facebook.com/reel/800885085193938',
      title: 'Project NUS Malaysian Students\' League',
      subtitle: 'Plot Listing'
    }
  ];

  const options = {
    loop: false,
    center: false,
    dots: true,
    nav: true,
    slideBy: 'page',
    responsive: {
      0: {
        items: 1,
        nav: false,
      },
      768: {
        margin: 30,
        items: 2,
        nav: false,
      },
      1024: {
        margin: 30,
        items: 3,
      },
      1200: {
        margin: 10,
        items: 3,
      }
    },
  };

  return (
    <div id="Portfolios">
      <Container className="our-portfolio">
        <div className="row col-lg-6 offset-lg-3 section-heading">
            <h2>
              Recent projects
            </h2>
        </div>
        <div className="row col-md-12">
          <OwlCarousel className="owl-carousel owl-portfolio" {...options}>
            {portfolioItems.map((item, index) => {
              return (
                <div key={index} className="item">
                  <div className="thumb">
                    <img src={item.imgSrc} alt="" />
                    <div className="hover-effect">
                      <div className="inner-content">
                        <a rel="sponsored" href={item.link} target="_blank">
                          <h4>{item.title}</h4>
                        </a>
                        {/* <span>{item.subtitle}</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </Container>
    </div>
  );
};

export default Portfolios;

import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import './styles//AboutUs.css';

const AboutUs = () => {
  return (
    <div id="About" className="about-us-container">
      <Container direction="column">
        <div className="about-us-text">
          <Typography variant="h4" gutterBottom>
            Welcome to Ori, your trusted partner in custom t-shirt manufacturing since the 1980s in Johor Bahru, Malaysia. 
          </Typography>
          <Typography variant="subtitle1" paragraph>
            Ori excels in providing high-quality, fully customizable t-shirts with unmatched customer service. Our commitment 
            to direct manufacturing ensures top-notch quality at affordable prices for a wide clientele, including corporates, 
            events, schools, and boutiques. Choose Ori for unparalleled customisation and quality in every stitch.
          </Typography>         
          {/* <Button variant="contained" color="primary" href="#Services">
            Explore Our Services */
          /* </Button> */}
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;

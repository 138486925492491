// ContactUs.js
import React, { useState } from 'react';
import { Grid, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import './styles/ContactUs.css'; // Import the CSS file

const ContactUs = () => {

  const [hoveredIcons, setHoveredIcons] = useState({
    facebook: false,
    instagram: false,
    linkedIn: false,
  });

  const handlePageChange = (url) => {
    window.open(url, "_blank");
  };

  const handleMouseEnter = (icon) => {
    setHoveredIcons((prev) => ({ ...prev, [icon]: true }));
  };

  const handleMouseLeave = (icon) => {
    setHoveredIcons((prev) => ({ ...prev, [icon]: false }));
  };
  
  return (
    <div id="ContactUs" className="contact-us-container">
      <Grid container direction={{ md:'row', xs:'column' }} spacing={{ md:5, xs:0 }} sx={{ pt:5, pb:5, pl:10, pr:10 }}>
        <Grid item md={4} xs={12}>
          
          {/* Contact Us Section */}
          <Grid container direction={{ md:'row', xs:'column'}} sx={{ pb:5 }} className="contact-us-text">
            <Grid item md={12} className="contact-us-text-container">
              <Typography variant="h4">Contact Us</Typography>
            </Grid>
            <Grid item md={12}>
              <LocalPhoneIcon fontSize="large" />
              <span>&nbsp;&nbsp;07-3542914</span>
            </Grid>
            <Grid item md={12}>
              <EmailIcon fontSize="large" />
              <span>&nbsp;&nbsp;sales@ori.com.my</span>
            </Grid>
            <Grid item md={12}>
              <WhatsAppIcon fontSize="large" />
              &nbsp;&nbsp;<a href="https://api.whatsapp.com/send/?phone=60167187272&text&type=phone_number&app_absent=0">+60 16-718 7272</a>
            </Grid>
            <Grid item md={12}>
              <TelegramIcon fontSize="large" />
              &nbsp;&nbsp;<a href="https://t.me/origarment">@origarment</a>
            </Grid>
          </Grid>

          {/* Follow Us Section */}
          <Grid container direction="row" sx={{ pb:5 }}>
            <Grid item xs={12} className="contact-us-text-container">
              <Typography variant="h4">Follow Us</Typography>
            </Grid>
            <Grid item xs={4} onClick={() => handlePageChange("https://www.facebook.com/ORIGARMENT/")}>
              <FacebookIcon className="contact-us-icons-container" fontSize="large" 
                onMouseEnter={() => handleMouseEnter('facebook')} onMouseLeave={() => handleMouseLeave('facebook')}
                color={hoveredIcons.facebook ? "primary" : "action"} />
            </Grid>
            <Grid item xs={4} onClick={() => handlePageChange("https://www.instagram.com/ori.garment?igsh=MXNwZ3VmbjF3dWJ4NA==")}>
              <InstagramIcon className="contact-us-icons-container" fontSize="large" 
                onMouseEnter={() => handleMouseEnter('instagram')} onMouseLeave={() => handleMouseLeave('instagram')} 
                color={hoveredIcons.instagram ? "secondary" : "action"} />
            </Grid>
            <Grid item xs={4} onClick={() => handlePageChange("https://www.linkedin.com/company/ori-garment/?viewAsMember=true")}>
              <LinkedInIcon className="contact-us-icons-container" fontSize="large" 
                onMouseEnter={() => handleMouseEnter('linkedin')} onMouseLeave={() => handleMouseLeave('linkedin')}
                color={hoveredIcons.linkedin ? "primary" : "action"}/>
            </Grid>  
          </Grid>    
        </Grid>

        {/* Find Us Section */}
        <Grid item md={8} xs={12} sx={{ pt: 0, pb: 0, pl: 0, pr: 0 }}>
          <Grid item className="contact-us-text-container">
            <Typography variant="h4">Find Us</Typography>
          </Grid>
          
          <Grid item>
            <iframe
              title="Google Maps"
              width="100%"
              height="425"
              className='contact-us-map'
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31907.16303991!2d103.7936112!3d1.5302674!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da6c6cc00f4041%3A0xfc0eed0f85791d63!2sOri%20Garment%20Sdn.%20Bhd.!5e0!3m2!1sen!2ssg!4v1710156802283!5m2!1sen!2ssg" 
              loading="lazy" 
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContactUs;

import React from 'react';
import Navbar from './Navbar';
import BackgroundImage from '../assets/bg_wallpaper.jpg';
import Logo from '../assets/Logo.png';
import './styles/Home.css';

const Home = () => {
  return (
    <div id="Home" className="home-container">
      <Navbar />
      <div className="background-image" style={{ backgroundImage: `url(${BackgroundImage})` }}></div>
      <img className="logo-image" src={Logo} alt="Centered" />
    </div>
  );
};

export default Home;

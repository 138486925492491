import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Container } from '@mui/material'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './styles/Testimonials.css'
import mslImg from '../assets/testimonial-msl.jpg';
import phImg from '../assets/testimonial-ph.png';

const Testimonials = () => {

    const testimonials = [
        {   
            name: 'Ilham',
            title: 'Vice project director (PH open house)',
            personImage: mslImg,
            comment: `Ordered shirts from Ori for Pioneer House Open House event in NUS and was very surprised by the quality. Item was delivered on time and very responsive too. The shirt colours were vibrant and the sizing, prints and color were excellent (even after repeated washing). Definitely recommended for anyone looking for quality custom tees!`
        },
        {
            name: 'Jayden Tan',
            title: 'President of Malaysian Student League',
            personImage: phImg,
            comment: `Quality: The shirts were excellent with fabric that looked sharp. \n\n
    Design & Service: The service is good, especially Chuan Zhe whom I contacted with. \n\n
    Delivery: Delivered on time and managed to get it done under a requirement of urgent need. We were satisfied and the shirts. \n\n
    Overall: ORI Company provided great quality and service. Would definitely recommend for custom shirts.`
        }
    ]
    
    

    const options = {
        loop: false,
        center: false,
        margin: 30,
        nav: true,
        dots: true,
        smartSpeed: 400,
        slideBy: "page",
        autoplay: false,
        responsive: {
          0: {
            items: 1,
            nav: false,
          },
          600: {
            items: 2,
          },
          1000: {
            items: 3,
          },
        },
      };

    return (
        <div id="Testimonials">
            <Container className='our-testimonials'>
                <div className="row col-lg-6 offset-lg-3 section-heading">
                    <h2>
                        Testimonials
                    </h2>
                </div>
                <div className="row col-md-12">
                    <OwlCarousel className="owl-carousel owl-testimonials" {...options}>
                        {testimonials.map((item) => {
                            return (
                                <Container>
                                    <div className="comment-section">
                                        <p style={{whiteSpace: "pre-line"}} className="testimonial-comment">{item.comment}</p>
                                    </div>
                                    <div className="bottom-section">
                                        <div className="person-image">
                                            <img src={item.personImage} alt="" />
                                        </div>
                                        <div className="details">
                                            <p className="title">{item.title}</p>
                                            <p className="name">{item.name}</p>
                                        </div>
                                    </div>
                                </Container>
                            );
                        })}
                    </OwlCarousel>
                </div>
            </Container>
        </div>
    );
};

export default Testimonials;
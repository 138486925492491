import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import { Container } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './styles/Services.css';
import image1 from "../assets/service-icon-01.png"
import image2 from "../assets/service-icon-02.png"
import image3 from "../assets/service-icon-03.png"

const Services = () => {

  const serviceItems = [
    {
      img: image1,
      title: "Custom T-Shirt Manufacturing",
      header: "Deep Customisation",
      subtitle: "Dive into unparalleled customisation options with us. From the thread to the trim every detail is yours to choose. We go beyond basic printing to fully tailor your design, ensuring your t-shirts perfectly capture your vision."
    },
    {
      img: image2,
      title: "Quality & Craftsmanship",
      header: "Unmatched Quality",
      subtitle: "Experience the gold standard in t-shirt manufacturing. Our direct control over production guarantees t-shirts that not only look exceptional but last longer. Trusted by our partners and clients for our high standards, we deliver excellence stitched into every piece."
    },
    {
      img: image3,
      title: "Comprehensive \n Services",    
      header: "From Concept to Completion",
      subtitle: "Enjoy a seamless journey from your initial idea to the final product.  Our team supports you at every step, offering expert guidance, innovative design solutions, and attentive customer service. Let us transform your concepts into reality with professionalism and a touch of fun."
    },
  ];

  const options = {
    loop: false,
    center: false,
    margin: 75,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      600: {
        margin: 15,
        items: 3,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
      },
      1000: {
        items: 3,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        freeDrag: false,
      },
    },
  };

  return (
    <div id="Services">
      <Container className="our-services">
        <div className="row col-lg-6 offset-lg-3 section-heading">
          <h2>
            Our Services
          </h2>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <OwlCarousel className="owl-carousel owl-services" {...options}>
              { serviceItems.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      <h4>{item.title}</h4>
                      <div className="icon">
                        <img src={item.img} alt="" />
                      </div>
                      <h5>{item.header}</h5>
                      <p>{item.subtitle}</p>
                    </div>      
                  );
              })}  
            </OwlCarousel>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Services;
